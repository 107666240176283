<script setup lang="ts">
import {Head, useForm, usePage} from '@inertiajs/inertia-vue3';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import {translate} from "../../../../ts/helpers/translationHelper";
import {Company} from "../../../../ts/models/Company";
import {computed} from "vue";
import Primary2Button from "@/Components/Primary2Button.vue";
import PasswordInput from "@/Components/PasswordInput.vue";

const props = defineProps({
	canResetPassword: Boolean,
	status: String,
	company: Company
});

const page = usePage();
const form = useForm({
	email: '',
	password: '',
	remember: false,
});


const submit = () => {
	console.log(route().current() + ".post");
	form.transform(data => ({
		...data,
		remember: form.remember ? 'on' : '',
	})).post(route(route().current() + ".post"), {
		onFinish: () => form.reset('password'),
	});
};

const textColor = computed(() => page.props.value.textColor);
const bgColor = computed(() => page.props.value.backgroundColor);
const secondaryColor = computed(() => page.props.value.secondaryColor);
const primaryColor = computed(() => page.props.value.primaryColor);

</script>

<template>
	<Head :title="translate('auth.login')"/>
	<form @submit.prevent="submit">
		<div>
			<InputLabel for="email" :value="translate('auth.email')"/>
			<TextInput
					id="email"
					v-model="form.email"
					type="email"
					class="mt-1 block w-full"
					required
					autofocus
			/>
			<InputError class="mt-2" :message="form.errors.email"/>
		</div>

		<div class="mt-4">
			<InputLabel for="password" :value="translate('auth.password_label')"/>
			<PasswordInput
					id="password"
					v-model="form.password"
					class="mt-1 block w-full"
					required
					autocomplete="current-password"
			/>
			<InputError class="mt-2" :message="form.errors.password"/>
		</div>

		<!--        <div class="block mt-4">-->
		<!--            <label class="flex items-center">-->
		<!--                <Checkbox v-model:checked="form.remember" name="remember"/>-->
		<!--                <span class="ml-2 text-sm text-gray-600">{{translate('auth.remember_me')}}</span>-->
		<!--            </label>-->
		<!--        </div>-->

		<div class="flex items-end justify-end mt-3 mb-8">
			<div :style="'color:'+textColor" class="ml-2" v-html="translate('auth.forgot_password_label', [
										{key: ':route', value:  route('password.request')},
												])">
			</div>
		</div>
		<Primary2Button class="w-full" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
			{{ translate('auth.login') }}
		</Primary2Button>
	</form>
</template>
